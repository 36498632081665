import { MasterData } from 'src/app/shared/model/master-data/master-data.model';
import { IDARemarks } from './interfaces/da-remarks.interface';
import { PrtDocument } from 'src/app/shared/model/prt-document.model';
import { DAStatusCodeLookup } from './da/da-status.enum';

export class DaService {
  public isLineApproved?: boolean;
  modifiedAt?: Date | null;
  remarks: IDARemarks[] = [];
  invoiceNumber: string = '';
  isWorldscale?: boolean;
  serviceGroup: MasterData = new MasterData();
  get mainLabel() {
    return this.version && this.version > 0 ? 'Supplementary' : 'Main';
  }
  savingsPercentage?: number;
  public readonly isDAPending?: boolean;
  isSelectedForSplit?: boolean;

  constructor(
    public code: string,
    public name: string,
    public exchangeRate: number | null,
    public localCurrency: string,
    public unitPriceLocal: number | null,
    public unitPriceUsd: number,
    public quantity: number | null,
    public amountExVatLocal: number | null,
    public amountExVatUsd: number | null,
    public amountLocal: number | null,
    public amountUsd: number | null,
    public vatTypeName: string | null,
    public vatRate: number | null,
    public vatAmountLocal: number | null,
    public vatAmountUsd: number | null,
    public supplierName: string | null,
    public agentRemarks: string | null,
    public hubRemarks: string | null,
    public principalRemarks: string | null,
    public rebillableRemarks: string | null,
    public costSavingsRemarks: string | null,
    public documents: PrtDocument[] = [],
    public costSavedLocal: number | null,
    public costSavedUsd: number | null,
    public isRebillable: boolean | null,
    public savingsTypeCode?: any,
    public savingsTypeName?: string,
    public isNew?: boolean,
    public isUpdated?: boolean,
    public downloading?: boolean,
    public version?: number | null,
    public payToParty?: string | null,
    public fundingParty?: string | null,
    public costAllocatedTo?: string | null,
    public approvalStatusCode?: string | null,
    public approvalStatusName?: string | null,
    public invoiceRemarks?: IDARemarks[],
    public costSummaryRemarks?: IDARemarks[],
    public rejectionInputRemarks?: string,
    public invoiceDetailId?: number | null,
  ) {
    this.rejectionInputRemarks = "";
    this.isDAPending = this.approvalStatusCode === DAStatusCodeLookup.Pending;
  }
}
